export const positionTranslations = {
  "Wing Back": "Außenverteidiger",
  Defender: "Defender",
  "Defensive Midfielder": "Mittelfeldspieler",
  "Attacking Midfielder": "Mittelfeldspieler",
  Midfielder: "Mittelfeldspieler",
  Striker: "Stürmer",
  Attacker: "Stürmer",
  Goalkeeper: "Torwart",
  // 'Substitute': 'Auswechselspieler',
};

export const positionExtension = {
  Midfielder: "Midfielder",
  Striker: "Striker",
  "Attacking Midfielder": "Midfielder",
  "Defensive Midfielder": "Midfielder",
  "Wing Back": "Defender",
  Defender: "Defender",
  Attacker: "Striker",
};

export const typeToLabels = {
  offensive: "Offensive",
  defensive: "Defensive",
};

export const seasonStatsLabel = {
  "Games Played": "Spiele",
  Goals: "Tore",
  "Goals Conceded Inside Box": "Tore innerhalb des Strafraums",
  "Goals Conceded Outside Box": "Tore außerhalb des Strafraums",
  "Headed Goals": "Kopfballtore",
  "Total Shots": "Torschüsse",
  "Shots On Target ( inc goals )": "Schüsse aufs Tor",
  "Corners Taken (incl short corners)": "Ecken",
  "Clean Sheets": "Zu-Null-Spiele",
  Catches: "Paraden des Torwarts",
  Duels: "Zweikämpfe",
  "Yellow Cards": "Gelbe Karten",
  Offsides: "Abseits",
  "Total Red Cards": "Rote Karten",
  "Total Fouls Conceded": "Fouls",
};
